<section>
	<h2 class="text-xl font-semibold text-black">Tell us about your experience</h2>
	<p class="text-gray-500">
		We always love to hear back from students. Feel free to leave us a review on
		<a
			class="blue-link"
			target="_blank"
			rel="noopener"
			href="https://uk.trustpilot.com/review/www.universitycompare.com"
			>TrustPilot</a
		>.
	</p>
</section>
