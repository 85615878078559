<div class="xl:pl-24 w-100">
	<h2 class="mb-5 text-4xl font-bold text-white" [innerHTML]="heading"></h2>

	<ul class="space-y-3">
		@for (benefit of benefits; track $index) {
			<li class="flex items-center gap-3">
				<uc-circular-checkmark-svg></uc-circular-checkmark-svg>
				<span class="text-lg text-white">{{ benefit }}</span>
			</li>
		}
	</ul>
</div>
