import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CircularCheckmarkSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-benefits-list',
	standalone: true,
	imports: [CircularCheckmarkSvgComponent],
	templateUrl: './benefits-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitsListComponent {
	@Input({ required: true }) heading!: string;
	@Input({ required: true }) benefits!: string[];
}
